<template>
  <div class="schedule container">
    <!-- <LeftMenus :menus="menus" :menusHandle="menusHandle" :active="active" /> -->
    <div :class="['mainContent', { overflow: overflow }]">
      <div class="filterHead">
        <div class="head">
          <div @click="showPicker = true">
            <span>{{ columns[selectIndex[0]]?.text }}</span>
            <span>
              {{ columns[selectIndex[0]]?.children[selectIndex[1]].text }}
            </span>
            <img
              :src="require('@/assets/imgs/schedule/down.svg')"
              class="title"
              alt
            />
          </div>
        </div>
        <DateTab :active.sync="dateTab" :dateData="dateDataModal" />
      </div>
      <div
        class="scheduleWrap"
        v-for="(group, groupKey) in scheduleModal"
        :key="groupKey"
      >
        <div class="dateTitle">{{ groupKey }}</div>
        <ScheduleItem
          v-for="(item, key) in group"
          :key="group + '' + key"
          :data="item"
        />
      </div>
    </div>
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        :columns="columns"
        toolbar-position="bottom"
        :show-toolbar="true"
        :item-height="36"
        @cancel="showPicker = false"
        @confirm="onConfirm"
        :visible-item-count="5"
        class="selectGroup"
        :confirm-button-text="$t('home.confirm')"
        :cancel-button-text="$t('home.cancel')"
      >
        <div slot="columns-top" class="dragWrap">
          <img :src="require('@/assets/imgs/comm/drag.svg')" alt />
        </div>
      </van-picker>
    </van-popup>
  </div>
</template>

<script>
// import LeftMenus from "@/components/LeftMenus";
import { getMatchList } from "@/service/Schedule";
import DateTab from "./components/DateTab";
import ScheduleItem from "./components/ScheduleItem";
import { Format } from "@/utils/fn";
import { getWeek } from "@/utils/time";
import { sphList, matchList, stageGroup } from "@/utils/filedList";
import { Popup, Picker } from "vant";
import { mapState } from "vuex";

export default {
  name: "Schedule",
  components: {
    // LeftMenus,
    DateTab,
    ScheduleItem,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
  },
  data() {
    return {
      active: "",
      dateTab: "all",
      dateData: [],
      scheduleData: [],
      groupDate: [],
      knockoutStageDate: [],
      fetchTimer: null,
      showPicker: false,
      columns: [],
      menus: [],
      selectIndex: [0, 0],
    };
  },
  created() {
    this.getMatchList(true);
    this.startQueue();
  },
  destroyed() {
    clearInterval(this.fetchTimer);
    this.fetchTimer = null;
  },
  computed: {
    scheduleModal() {
      let _data = this.scheduleModalData.reduce((prev, item) => {
        if (
          item.value &&
          (this.dateTab == "all" || item.dateTime == this.dateTab)
        ) {
          prev[item.dateTime] = prev[item.dateTime] || [];
          prev[item.dateTime].push(item);
        }
        return prev;
      }, {});
      return _data;
    },

    scheduleModalData() {
      if (this.columns.length) {
        let active =
          this.columns[this.selectIndex[0]].children[this.selectIndex[1]].value;
        return this.scheduleData.filter((item) => {
          return (
            (this.activeGroupValueModal.indexOf(item.value) > -1 ||
              item.value == active) &&
            (this.dateTab == "all" || item.dateTime == this.dateTab)
          );
        });
      }
      return [];
    },
    activeGroupValueModal() {
      if (this.columns.length) {
        let active =
          this.columns[this.selectIndex[0]].children[this.selectIndex[1]].value;
        let _active = active ? active + "" : "";
        let idx = _active.indexOf("all");
        if (idx > -1) {
          let activeId = _active.replace("all", "");
          return stageGroup[activeId].group;
        }
      }
      return [];
    },
    dateDataModal() {
      if (this.columns.length) {
        let active =
          this.columns[this.selectIndex[0]].children[this.selectIndex[1]].value;
        return this.dateData.filter((item) => {
          let hasGroupVal = false;
          if (this.activeGroupValueModal.length) {
            hasGroupVal = this.activeGroupValueModal.find((groupVal) => {
              return item.groupValueArr.indexOf(groupVal) > -1;
            });
          }
          return hasGroupVal || item.groupValueArr.indexOf(active) > -1;
        });
      }
      return [];
    },
    selectData() {
      const matchArr = [9, 10, 11, 12, 13];
      let matchVal = this.columns[this.selectIndex[0]].values;
      let groupVal =
        this.columns[this.selectIndex[0]].children[this.selectIndex[1]].values;
      return this.scheduleData.filter((item) => {
        return (
          ((matchVal == "all" && !(matchArr.indexOf(item.sph) > -1)) ||
            item.sph == matchVal) &&
          (groupVal == "all" || item.sph == groupVal)
        );
      });
    },
    languageType() {
      return this.common.languageType;
    },
    overflow: {
      get() {
        return this.common.overflow;
      },
      set(val) {
        this.$store.commit("common/SET_DATA", {
          name: "overflow",
          data: val,
        });
      },
    },
    ...mapState(["common"]),
  },
  methods: {
    onConfirm(currentValue, currentIndex) {
      this.dateTab = "all";
      this.selectIndex = currentIndex;
      this.showPicker = false;
    },
    menusHandle(val) {
      this.active = val;
    },
    startQueue() {
      if (this.fetchTimer) {
        clearInterval(this.fetchTimer);
        this.fetchTimer = null;
      }
      this.fetchTimer = setInterval(() => {
        this.getMatchList();
      }, 3000);
    },
    getMatchList(firstLoad) {
      getMatchList({ languageType: this.languageType }).then((res) => {
        if (res.success) {
          this.buildData(res.data, firstLoad);
        }
      });
    },
    buildDate(btm) {
      let dateTime = Format(new Date(btm), "yyyy-MM-dd");
      let formatWeek = getWeek(btm)[this.getLocal()];
      let dateFormat = Format(new Date(btm), "M-d");
      return {
        dateTime,
        formatWeek,
        dateFormat,
        btm,
      };
    },
    buildGroup(sph) {
      if (sphList[sph]) {
        return {
          value: sph,
          text: sphList[sph][this.getLocal()],
          groupSort: sphList[sph].sort || sphList[sph].EN,
        };
      }
      if (matchList[sph]) {
        return {
          value: sph,
          text: matchList[sph][this.getLocal()],
          groupSort: matchList[sph].groupSort,
        };
      }
      if (!sphList[sph] && !matchList[sph]) return false;
    },
    buildData(data, firstLoad) {
      let scheduleTemp = [];
      let dateDataTemp = [];
      let menusTemp = [];
      let _firstData = false;
      data.forEach((item) => {
        let itemTemp = { ...item };
        // 時間格式
        const timeObj = this.buildDate(item.btm);
        let hasOne = dateDataTemp.find((dateItem) => {
          if (dateItem.dateTime == timeObj.dateTime) {
            dateItem.groupValueArr.push(item.sph);
            return true;
          }
        });
        // 分組
        let groupTemp = this.buildGroup(item.sph);
        if (!groupTemp) {
          return;
        }
        if (!hasOne) {
          timeObj.groupValueArr = [item.sph];
          timeObj.text = groupTemp.text;
          timeObj.value = groupTemp.value;
          dateDataTemp.push(timeObj);
        }
        let hasOneGroup = menusTemp.find(
          (item) => item.value == groupTemp.value
        );

        let stageVal = [];
        if (sphList[item.sph]) {
          stageVal = Object.values(stageGroup).filter((item) => {
            return item.group.includes(itemTemp.sph);
          });
        }
        if (stageVal.length) {
          const hasStage = menusTemp.find((menu) => {
            if (menu.children) {
              return menu.children.find((stage) => {
                return stageVal[0].group.includes(stage.value);
              });
            }
            return menu.value == groupTemp.value;
          });
          if (!hasStage) {
            menusTemp.push({
              value: stageVal[0].id,
              text: stageVal[0][this.getLocal()],
              groupSort: stageVal[0].sort,
              children: [
                {
                  value: "all" + stageVal[0].id,
                  text: this.$t("home.All"),
                  groupSort: "A",
                },
                groupTemp,
              ],
            });
          } else {
            let hasTem = hasStage.children.find((stage) => {
              return stage.value == groupTemp.value;
            });
            if (!hasTem) {
              hasStage.children.push(groupTemp);
            }
          }
        } else if (!hasOneGroup) {
          menusTemp.push({
            ...groupTemp,
            children: [
              {
                value: groupTemp.value,
                text: this.$t("home.All"),
                groupSort: "A",
              },
            ],
          });
        }
        itemTemp = { ...itemTemp, ...timeObj, ...groupTemp };
        scheduleTemp.push(itemTemp);
      });
      this.dateData = dateDataTemp.sort(
        (curr, next) => new Date(curr.btm) - new Date(next.btm)
      );
      menusTemp.forEach((item) => {
        if (item.children) {
          item.children = item.children.sort((a, b) =>
            (a.groupSort + "").localeCompare(b.groupSort + "")
          );
        }
      });
      if (!this.columns.length) {
        this.columns = menusTemp.sort((a, b) =>
          (a.groupSort + "").localeCompare(b.groupSort + "")
        );
      }
      if (this.active === false) {
        this.active = this.columns[0].children
          ? this.columns[0].children[0].value
          : this.columns[0].value;
      }
      this.scheduleData = scheduleTemp.sort(
        (curr, next) => curr.btm - next.btm
      );
      this.scheduleData.forEach(item => {
        // 第一次進入默認選未開賽第一場
        if((item.st === undefined || item.st === 4) && !_firstData && firstLoad){
          _firstData = item;
        }
      })
      if(_firstData){
        this.dateTab = _firstData.dateTime;
        let stageVal = Object.values(stageGroup).filter((item) => {
          return item.group.includes(_firstData.sph);
        });
        let groupId = stageVal.length ? stageVal[0].id : _firstData.sph;
        let idx1 = this.columns.findIndex((item) => item.value == groupId)
        if(idx1 > -1){
          let idx2 = this.columns[idx1].children.findIndex((item) => item.value == _firstData.sph)
          this.selectIndex = [idx1, idx2 > -1 ? idx2 : 0];
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.schedule {
  height: 100%;
  .mainContent {
    overflow: hidden;
    height: 100%;
    padding-bottom: 20px;
    background: #f4f4f4;
    border-radius: 20px 20px 0 0;
    padding-bottom: 75px;
    &.overflow {
      padding-top: 86px;
      overflow: auto;
      .filterHead {
        background: $bg-color;

        position: fixed;
        top: 44px;
        z-index: 99;
        left: 0;
        right: 0;
      }
      .head {
        border-radius: 0;
      }
    }

    .head {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      background: #fff;
      border-radius: 20px 20px 0 0;
      * {
        margin-right: 4px;
        vertical-align: -webkit-baseline-middle;
      }
      span {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        &:first-child {
          font-weight: 600;
        }
      }
    }
  }
  .scheduleWrap {
    margin: 4px 8px 0;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    .dateTitle {
      height: 40px;
      padding-left: 16px;
      position: relative;
      line-height: 40px;
      background: #fbfbfb;
      color: #333958;
      font-weight: 500;
      &::before {
        content: " ";
        position: absolute;
        width: 4px;
        left: 0px;
        top: 20%;
        bottom: 17.5%;
        border-radius: 0 5px 5px 0;
        background: #d80709;
      }
    }
  }
}

.dragWrap {
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<style lang="scss">
.selectGroup {
  .van-picker__toolbar {
    button {
      flex: 1;
      color: #fff;
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #ffffff;
    }
    .van-picker__cancel {
      background: #aeb4bd;
    }
    .van-picker__confirm {
      background: #d80709;
    }
  }
}
.dark {
  .schedule {
    .mainContent {
      background: #000;
      .head {
        background: #151618;
        color: #ffffff;
      }
    }
    .scheduleWrap {
      background: #151618;
      .scheduleItem{
        &:last-child{
          border: none;
        }
      }
      .dateTitle {
        background: #292c30;
        color: #ffffff;
      }
    }
  }
    .van-popup{
      .van-picker{
      background: #151618;
      }
      background: #151618;
      [class*='van-hairline']::after{
            border: 0 solid #2B2C30;
                border-width: 1px 0;
      }
      .van-picker-column__item{
        color: #fff;
      }
      .van-picker__mask{
        background: none;
      }
    }
}
</style>
